import React, { useState, useEffect } from 'react';
import axios from "axios";
const EnqryForm = () => {
  const [name, setUsername] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [Query, setQuery] = useState();
  
  const handleClickEvent = () => {
    postquery();
  }
  const postquery= async()=>{
    const post = { custname:name, custEmail:email,custMobile:mobile}
    console.log(post);
    const response = await axios.post("https://api.stywork.in/savequery", post);
    //const response = await axios.post("http://localhost:5000/savequery", post);
    console.log(response.data);
  }
  return (
    <>
      {/* Query section */}
      <section className="professional_section contact_section layout_padding form_section">
        <img src='../images/contener_bg.jpg' className='form_bg_design'/>
        <div className='container'>
          <div className="row" style={{ margin: "0 auto;" }}>
            <div className="col-lg-7 col-md-12 enqry_form_cont">
              <div className="detail-box">
                <h2>Post your Requirement</h2>
                {/* <p>Please provide basic details. We contact you shortlly.</p> */}
                <form action="" method="post" onSubmit={handleClickEvent}>
                    <>
                    <div className='row'>
                    <div className='col-md-6'>
                        <label>Name<span className='text-danger'>*</span></label>
                        <input type="text" name="Name"
                          className="form-control" id="Name" required
                          onChange={(e)=>setUsername(e.target.value)} />
                      </div>
                      <div className='col-md-6'>
                        <label>Mobile<span className='text-danger'>*</span></label>
                        <input type="text" name="Mobile"
                          className="form-control" id="Mobile" required
                          onChange={(e)=>setMobile(e.target.value)} />
                      </div>
                      <div className='col-md-12'>
                        <label>Email Id<span className='text-danger'>*</span></label>
                        <input type="text" name="Email"
                          className="form-control" id="Email" required
                          onChange={(e)=>setEmail(e.target.value)} />
                      </div>
                    </div>
                      <div>
                        <label>Query<span className='text-danger'>*</span></label>
                        <textarea name="txt_query"
                          className="form-control" id="txt_query" required
                          onChange={(e)=>setQuery(e.target.value)} rows={5} cols={20}></textarea>
                      </div>
                    </>
                  <div>
                    <button type="button" id="btn_enqury" className='btn btn-success btn_design' onClick={handleClickEvent}>
                      Submit query</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* end about section */}
    </>
  );
};
export default EnqryForm;